import React, { useState, useEffect } from 'react';
import { ArrowRight, Clock, Globe, Headphones, Wallet, ChevronLeft, ChevronRight } from 'lucide-react';
import logo from './logo.png';

const PhoenixGames = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const games = [
    {
      title: "Rock Paper Scissors",
      description: "Challenge friends in real-time!",
      bgColor: "from-blue-600 to-purple-600"
    },
    {
      title: "Spin Wheel",
      description: "Win big with every spin!",
      bgColor: "from-purple-600 to-pink-600"
    },
    {
      title: "Fast Bingo",
      description: "Quick wins await!",
      bgColor: "from-pink-600 to-red-600"
    },
    {
      title: "Aviator",
      description: "Fly high, cash out higher!",
      bgColor: "from-red-600 to-orange-600"
    },
    {
      title: "Black Jack",
      description: "Beat the dealer!",
      bgColor: "from-orange-600 to-yellow-600"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      const targetDate = new Date('December 1, 2024 00:00:00').getTime();
      const now = new Date().getTime();
      const difference = targetDate - now;

      setCountdown({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let slideInterval;
    if (isAutoPlaying) {
      slideInterval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % games.length);
      }, 3000);
    }
    return () => clearInterval(slideInterval);
  }, [isAutoPlaying]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % games.length);
    setIsAutoPlaying(false);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + games.length) % games.length);
    setIsAutoPlaying(false);
  };

  const gameCategories = [
    {
      title: "🎮 Action-Packed Classics",
      description: "Test your skills and strategy!",
      games: [
        { name: "Rock Paper Scissors", highlight: "Challenge friends in real-time!" },
        { name: "Spin Wheel", highlight: "Win big with every spin!" },
        { name: "Dice Rolling", highlight: "Pure luck and excitement" },
        { name: "Card Finding", highlight: "Test your memory" },
        { name: "Pool Number", highlight: "Precision and skill combined" }
      ]
    },
    {
      title: "🎰 Premium Casino Experience",
      description: "Feel the thrill of the casino!",
      games: [
        { name: "Roulette", highlight: "Classic casino action" },
        { name: "Black Jack", highlight: "Beat the dealer!" },
        { name: "Poker", highlight: "Show your best hand" },
        { name: "Number Slot", highlight: "Jackpots await!" }
      ]
    },
    {
      title: "🎱 Exclusive Bingo Collection",
      description: "Ethiopia's favorite bingo varieties!",
      games: [
        { name: "Fast Bingo", highlight: "Quick wins!" },
        { name: "Superfast Bingo", highlight: "Lightning fast action" },
        { name: "አባዥ Bingo", highlight: "Traditional favorite" },
        { name: "Online + Offline Bingo", highlight: "Play anywhere" },
        { name: "Telegram Bingo", highlight: "Connect & win" },
        { name: "ዙር Bingo", highlight: "Round after round of fun" },
        { name: "ጊዜ Bingo", highlight: "Time your victory" }
      ]
    }
  ];

  const menuItems = [
    "Rock Paper Scissors",
    "Spin Wheel",
    "Fast Bingo",
    "Aviator",
    "Black Jack",
    "Roulette",
    "Poker"
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900 text-white">
      {/* Navigation Menu */}
      <nav className="bg-black/50 backdrop-blur-sm border-b border-gold/20 sticky top-0 z-50">
        <div className="container mx-auto px-4 md:px-6">
          <div className="flex items-center justify-between h-16">
            <img 
              src={logo}
              alt="Phoenix Games Logo" 
              className="h-8 md:h-10"
            />
            <div className="hidden md:flex space-x-8">
              {menuItems.map((item, index) => (
                <button 
                  key={index}
                  className="text-gold hover:text-gold/80 transition-colors text-sm uppercase tracking-wider"
                >
                  {item}
                </button>
              ))}
            </div>
            <button className="bg-gradient-to-r from-gold to-amber-500 text-black px-4 py-2 rounded-full text-sm font-bold hover:from-amber-500 hover:to-gold transition-all duration-300">
              Play Now
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section with new gradient */}
      <header className="bg-gradient-to-r from-black via-yellow-900 to-black py-20 md:py-32 relative overflow-hidden">
        <div className="container mx-auto px-4 md:px-6 text-center relative z-10">
          <img 
            src={logo}
            alt="Phoenix Games Logo" 
            className="mx-auto h-20 md:h-32 mb-6 md:mb-8"
          />
          
          {/* Countdown Timer */}
          <div className="bg-black/30 backdrop-blur-sm border border-gold/20 rounded-xl p-6 max-w-2xl mx-auto mb-12">
            <h3 className="text-xl md:text-2xl mb-6 text-gold">Launch Countdown</h3>
            <div className="grid grid-cols-4 gap-4 md:gap-6">
              {Object.entries(countdown).map(([unit, value]) => (
                <div key={unit} className="bg-gradient-to-br from-purple-900/50 to-black rounded-lg p-4 border border-purple-500/20">
                  <div className="text-2xl md:text-4xl font-bold text-gold">{value}</div>
                  <div className="text-xs md:text-sm uppercase text-purple-300">{unit}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Game Slideshow */}
          <div className="relative max-w-4xl mx-auto mb-8 h-48 md:h-64">
            <div className="absolute inset-0 flex items-center justify-between z-10 px-2">
              <button 
                onClick={prevSlide}
                className="bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all"
              >
                <ChevronLeft className="w-6 h-6" />
              </button>
              <button 
                onClick={nextSlide}
                className="bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all"
              >
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
            
            <div className="relative h-full overflow-hidden rounded-xl">
              {games.map((game, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 flex items-center justify-center bg-gradient-to-r ${game.bgColor} transition-opacity duration-500 ${
                    index === currentSlide ? 'opacity-100' : 'opacity-0 pointer-events-none'
                  }`}
                >
                  <div className="text-center p-4">
                    <h3 className="text-2xl md:text-4xl font-bold mb-2">{game.title}</h3>
                    <p className="text-sm md:text-lg">{game.description}</p>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Slide Indicators */}
            <div className="absolute bottom-2 left-0 right-0 flex justify-center gap-2">
              {games.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setCurrentSlide(index);
                    setIsAutoPlaying(false);
                  }}
                  className={`w-2 h-2 rounded-full transition-all ${
                    index === currentSlide ? 'bg-white w-4' : 'bg-white/50'
                  }`}
                />
              ))}
            </div>
          </div>

          <button className="bg-purple-600 hover:bg-purple-700 px-6 md:px-8 py-2 md:py-3 rounded-full font-semibold transform hover:scale-105 transition-transform text-sm md:text-base">
            Play Now
          </button>
        </div>
      </header>

      {/* Game Categories */}
      <section className="py-8 md:py-16">
        <div className="container mx-auto px-4 md:px-6">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">Our Gaming Universe</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {gameCategories.map((category, index) => (
              <div 
                key={index} 
                className="bg-gradient-to-br from-black via-purple-900/20 to-black rounded-xl p-6 
                           border border-gold/10 hover:border-gold/30 transition-all duration-300
                           backdrop-blur-sm hover:shadow-2xl hover:shadow-purple-500/10"
              >
                <h3 className="text-xl md:text-2xl font-bold mb-2 text-gold">{category.title}</h3>
                <p className="text-purple-300 mb-4 text-sm md:text-base">{category.description}</p>
                <div className="space-y-3">
                  {category.games.map((game, gameIndex) => (
                    <div 
                      key={gameIndex} 
                      className="bg-black/40 p-4 rounded-lg hover:bg-black/60 transition-all
                                 border border-purple-500/10 hover:border-purple-500/30"
                    >
                      <div className="flex justify-between items-center flex-wrap gap-2">
                        <span className="font-semibold text-sm md:text-base text-gold/90">{game.name}</span>
                        <span className="text-xs md:text-sm text-purple-300">{game.highlight}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-8 md:py-16 bg-gray-800">
        <div className="container mx-auto px-4 md:px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">About Phoenix Games</h2>
            <p className="text-gray-300 mb-6 md:mb-8 text-sm md:text-base">
              Phoenix Games is one of the biggest gaming providers in the East African market, 
              serving customers in Ethiopia, Kenya, Tanzania, and Uganda. We pride ourselves on 
              offering a diverse selection of games, reliable platform, and excellent customer support.
            </p>
            <button className="bg-purple-600 hover:bg-purple-700 px-6 md:px-8 py-2 md:py-3 rounded-full font-semibold transform hover:scale-105 transition-transform text-sm md:text-base">
              Learn More
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-6 md:py-8">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <p className="text-gray-400 text-sm">© 2024 Phoenix Games. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PhoenixGames;